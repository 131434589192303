<template>

    <v-col cols="12" sm="12" md="2">
        
        <div 
            class="cfl-item"
            :class="{ 'active-cfl': attachment.IsActive }"
        >
            <div 
                v-if="attachment.Progress"
                class="cfl-item-progress"
                :style="{ width: attachment.Progress }"
            >
            </div>
            <div class="file-icon">
                <!--Для добавления стикера сертификата на иконку добавляем у тега <i> к существующим классам класс with-cert и еще второй класс для управления цветом success-cert или danger-cert -->
                <v-icon :color="getIconColor(attachment.Extension)">
                    {{ getIcon(attachment.Extension) }}
                </v-icon>
            </div>
            <div class="cfl-info">
                <div class="cfl-abs" v-tooltip.left-center="getAttachmentName(attachment)">
                    <div class="cfl-filename">{{ getAttachmentName(attachment) }}</div>
                    <div>
                        <div class="cfl-fileinfo">
                            <div v-if="attachment.Message" class="cfl-err-msg">{{ attachment.Message }}</div>
                            <span v-else-if="attachment.Progress">{{ attachment.Progress }}</span>
                            <span v-else>{{ parseInt(attachment.Length) ? `${(parseFloat(attachment.Length) / (1024 * 1024)).toFixed(2)} ${$t('МБ')}` : '-' }}</span>                                                
                        </div>
                        <!-- <div class="cfl-status"></div> -->
                    </div>
                </div>
            </div>
            <div class="cfl-actions"
                v-if="!attachment.Progress"
            >
                <span 
                    v-tooltip.left-center="$t('Информация_о_файле')"
                    @click="onAttachmentInfo(attachment)"
                >
                    <i class="fas fa-info-circle dark-gray-color"></i>
                </span>
                <span 
                    v-if="!attachment.Message"
                    class="download-file" 
                    v-tooltip.left-center="$t('Загрузить')"
                    @click.stop="onLoadAttachmet(attachment)"
                >
                    <i class="fas fa-download dark-gray-color"></i>
                </span>
                <span
                    v-if="isDeleteable" 
                    class="delete-file"
                    v-tooltip.left-center="$t('Удалить')"
                    @click.stop="onDeleteAttachment(attachment)"
                >
                    <i class="fas fa-trash dark-gray-color"></i>
                </span>
            </div>
        </div>
       
    </v-col>

</template>

<script>

export default {
    name: "FileItem",
    get supportedFormats() { 
        return [
            ".PDF",
            ".JPG",
            ".JPEG",
            ".PNG",
            ".DOC",
            ".DOCX",
            ".XLS",
            ".XLSX",
            ".PPT",
            ".PPTX",
            ".RTF",
            ".TXT",
            ".XML",
            ".ODT",
            ".ODS",
            ".ODP",
            ".CSV"
        ]
    },
    fileExtensionsAssociationIcons: {
        pdf: 'fas fa-file-pdf',
        jpg: 'fas fa-file-image',
        jpeg: 'fas fa-file-image',
        png: 'fas fa-file-image',
        doc: 'fas fa-file-word',
        docx: 'fas fa-file-word',
        xls: 'fas fa-file-excel',
        xlsx: 'fas fa-file-excel',
        rar: 'fas fa-file-archive',
        zip: 'fas fa-file-archive',
        '7z': 'fas fa-file-archive',
        ppt: 'fas fa-file-powerpoint',
        pptx: 'fas fa-file-powerpoint',
        csv: 'fas fa-file-csv',
        odt: 'fas fa-file-word',
        rtf: 'fas fa-file-word',
        ods: 'fas fa-file-excel',
        txt: 'fas fa-file-alt'
    },
    fileExtensionsAssociationIconsColors: {
        pdf: '#ba141a',
        jpg: '#662d91',
        jpeg: '#662d91',
        png: '#662d91',
        doc: '#0054a6',
        docx: '#0054a6',
        xls: '#008641',
        xlsx: '#008641',
        ppt: '#f04e23',
        pptx: '#f04e23',
        csv: '#008641',
        ods: '#008641',
        odt: '#0054a6',
        rtf: '#0054a6',
        txt: '#0054a6',
    },
    data() {
        return {
            
        }
    },
    props: {
        colWidth: {
            type: String,
            default: "3"
        },
        attachment: {
            type: Object,
            default: null
        },
        isDeleteable: {
            type: Boolean,
            default: false
        },
        onDeleteAttachment: Function,
        onLoadAttachmet: Function,
        onAttachmentInfo: Function
    },
    computed: {
       
    },
    methods: {
        getIcon(fileExtension) {

        if (fileExtension != null && typeof fileExtension === 'string' && fileExtension.length > 0) {
            return this.$options.fileExtensionsAssociationIcons[fileExtension.replace('.', '')] ?? 'fas fa-file';
        }

        return 'fas fa-file';
        },
        getIconColor(fileExtension) {

        if (fileExtension != null && typeof fileExtension === 'string' && fileExtension.length > 0) {
            return this.$options.fileExtensionsAssociationIconsColors[fileExtension.replace('.', '')] ?? '#b4b4b4';
        }

        return '#b4b4b4';
        },
        getAttachmentName(attachment) {

            if (this.$store.getters['global/actionsource/getDataSourceType'] === 'IQalaStatement' && attachment.Description != null && typeof attachment.Description === 'string' && attachment.Description.length > 0)
                return attachment.Description;

            return attachment.Name;
        }
    }
}
</script>